import { faker } from "@faker-js/faker"

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max)
}

type GeneratorFunction = () => string
function callFunctionFromCategory(categoryOfFunctions: GeneratorFunction[]) {
  const theChosenOne = getRandomInt(categoryOfFunctions.length - 1)
  return categoryOfFunctions[theChosenOne]()
}

// Categories from Faker
// https://github.com/marak/Faker.js/
const categories = [
  [
    faker.animal.dog,
    faker.animal.cat,
    faker.animal.snake,
    faker.animal.cetacean,
    faker.animal.bird,
    faker.animal.fish,
    faker.animal.insect,
  ],
  [faker.commerce.color, faker.commerce.department, faker.commerce.productName, faker.commerce.productMaterial],
  [faker.company.catchPhrase, faker.company.bsBuzz],
  [
    faker.hacker.abbreviation,
    faker.hacker.adjective,
    faker.hacker.noun,
    faker.hacker.verb,
    faker.hacker.ingverb,
    faker.hacker.phrase,
  ],
]

function pickRandomCategory() {
  const random = getRandomInt(categories.length - 1)
  return categories[random]
}

function generateSomething() {
  return callFunctionFromCategory(pickRandomCategory())
}

export default function generateString(targetLength: number) {
  let generated = ""
  while (generated.trim().length < targetLength) {
    generated += generateSomething() + " "
  }
  return generated.trim().slice(length)
}
